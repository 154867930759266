
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ui-admin-primary: mat.define-palette(mat.$indigo-palette);
$ui-admin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ui-admin-warn: mat.define-palette(mat.$red-palette);



$ui-admin-typography: mat.define-typography-config(
  $font-family: Roboto,
);





// Create the theme object (a Sass map containing all of the palettes).
//oud
// $ui-admin-theme: mat.define-light-theme($ui-admin-primary, $ui-admin-accent, $ui-admin-warn, {density: -2});
//nieuw
$ui-admin-theme: mat.define-light-theme((
 color: (
   primary: $ui-admin-primary,
   accent: $ui-admin-accent,
   warn: $ui-admin-warn
 ),
 typography: $ui-admin-typography, 
 density: -1
));

//voorbeeld
// $dark-theme: mat.define-dark-theme((
//   color: ...,
//   typography: ...,
//   density: -2,
// ));


// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($ui-admin-theme);


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ui-admin-theme);


/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
html, body { height: 100%; width: 100% }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-sidenav, .drawer, .mat-toolbar.mat-primary {
  background: #337ab7 !important;
  // overflow: hidden;
  // position: relative;
}


.mat-sidenav .mat-nav-list .mat-mdc-list-item {
  color: #fff;
}

.mat-column-id {
  flex: 0 0 10% !important;
  width: 10% !important;
}

 .mat-column-name {
   flex: 0 0 25% !important;
   width: 25% !important;
 }

 .mat-column-lastName {
  flex: 0 0 140px !important;
  width: 140px !important;
}


.table-header {
  background-color: #327ebd;
  color: white;
  padding: 0 20px !important;
}

.text-left {
  text-align: left !important;
  margin: 10px !important;;
}



.list-table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-align: left;
  color: #bbb;
  font-family:Roboto,"Helvetica Neue",sans-serif;
  text-transform: uppercase;
}

.list-table .mat-icon {
  color: #327ebd;
  cursor: pointer;
  outline: none;
}

.primary-blue {
  background: #327ebd;
  color: #fff;
  float: right;
  right: 20px;
}


.table-container {
  padding: 5px 15px 15px 15px;
}

.table-component-holder {
  border: 1px solid #327ebd;
  background-color: white;
}

.components-container-gt-xs {
  padding: 20px;
}

// .primary-blue-btn {
//   background: #327ebd;
//   color: #fff;
// }

.danger-btn {
  background: red;
  color: #fff;
}

.mat-toolbar.stepper-header.mat-toolbar.mat-toolbar-single-row.mat-tab-group {
  background:#337ab7 !important;
  color: white;
  padding: 0 5px;
}


.color-set {
  color : #327ebd !important;
}

.clickable {
  cursor: pointer;
}

.text-center {
  text-align: center;
}


.btn-primary {
  background: #327ebd !important;
}

// .white-text {
//   color: white;
// }

.text-right {
  text-align: right;
}

.resp-table {
  .table-header {
    background-color: #327ebd;
    color: white;
    padding: 0 20px;
  }
  .table-container {
    padding: 5px 15px 15px 15px;
  }
  .table-component-holder {
    border: 1px solid #327ebd;
    background-color: white;
  }

  table {
    // border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
    font-family:Roboto,"Helvetica Neue",sans-serif;
  }
  table tr {
    border-bottom: 1px solid #ddd !important;
    padding: .35em;
  }
  table tr:nth-child(odd) td{
    font-family:Roboto,"Helvetica Neue",sans-serif;
  }
  table tr:nth-child(even) td{
    font-family:Roboto,"Helvetica Neue",sans-serif;
  }
  .mat-sort-header-container{
    justify-content: start;
  }
  table th,
  table td {
    padding: .625em;
    text-align: left;
    font-family:Roboto,"Helvetica Neue",sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
  }
  table th {
    background: #fff;
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }
    table caption {
      font-size: 1.3em;
    }
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px !important;
    }
    table td:before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    table td:last-child {
      border-bottom: 0;
    }

  }

  ::ng-deep .mat-sort-header-container{
    justify-content: start;
  }

  /* Structure */
  .example-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
  }

  .example-header {
    min-height: 64px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    font-size: 20px;
  }

  .mat-mdc-table {
    overflow: auto;
    max-height: 500px;
  }

  .widgetHolder{
    margin: 0 auto;
    .widgetHolder-content {
      padding: 20px;
    }
  }

}

.th-wallet {
  width: 40px;
}

.th-id {
  width: 80px;
}

.th-edit {
  width: 40px;
}

.th-delete {
  width: 40px;
}


.pt0 {
    padding-top: 0 !important;
}

.float-right {
    float:right;
}

.responsive-table th[ng-reflect-id=wallet] {
  width: 40px !important;
}

.filter-btn {
  height: 46px;
  margin-top: 2px;
}



.pb0 {
  padding-bottom: 0;
}

app-responsivetable thead {
  border-top: 1px solid #ddd;
}

app-responsivetable thead th {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.white-bg {
  background: #fff;
}


.fieldwidthLarge {
  width: 100% !Important;
}

.fieldwidthMedium{
  width: 50% !Important;
}

.fieldwidthSmall{
  width: 25% !Important;
}

a, button {
  cursor: pointer !important;
}

sidenav-menuitem mat-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


// .mat-column-email {
//     flex: 0 0 200px !important;
//     width: 200px !important;
//     word-wrap: break-word !important;
//     white-space: unset !important;
//     overflow-wrap: break-word;
//     word-wrap: break-word;

//     word-break: break-word;

//     -ms-hyphens: auto;
//     -moz-hyphens: auto;
//     -webkit-hyphens: auto;
//     hyphens: auto;
//   }


  // .mat-column-accessToken, .mat-column-accessTokenToken {
  //   word-wrap: break-word !important;
  //   white-space: unset !important;
  //   width: 190px !important;
  //   overflow-wrap: break-word;
  //   word-wrap: break-word;

  //   word-break: break-word;

  //   -ms-hyphens: auto;
  //   -moz-hyphens: auto;
  //   -webkit-hyphens: auto;
  //   hyphens: auto;
  // }

  .mat-column-userName {
    flex: 0 0 200px !important;
    width: 200px !important;
  }


.link {
  color : #327ebd !important;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}

.mat-mdc-tooltip {
  font-size: 18px;
  background-color: #337ab7 !important;
  white-space: pre-line;
}


// KVG: nieuwe classes na upgrade Material 15 
td:has(mat-icon) {
  //alleen voor testen:
	// background-color: yellow !important;
	// border: 1px solid green;
	width: 50px !important;
	// max-width: 50px !important;
  color : #327ebd !important;
  display: table-cell;
  height: 100% !important;
	// justify-content: center;
  text-align: center !important;
  
}


/* button colors */
button.mat-primary {
  background-color: #327ebd !important;
  color: white !important;
  &[disabled="true"] {
    background-color: lightgray !important;
    color: darkgray !important;
  }
}
// button.mat-warn {
//   background-color: green !important;
// }


.input-container {
  padding: 15px;
}


mat-toolbar {
  display: flex;
  h1 {
    font: 500 16px/32px Roboto, "Helvetica Neue", sans-serif;
    text-align: left;
    width: 100%;
  }
  & > button {
    margin: 20px 10px;
    min-width: auto !important;
    padding: 5px;
  }
  &.bottom-bar {
    background-color: transparent !important; 
  }
}


.rightalign-spacer {
  flex: 1 1 auto;
}


// <mat-card>
mat-card {
  margin: 25px;
}

mat-card-header, mat-card-content, mat-card-actions {
  margin: 8px;
}

mat-card-actions button {
  margin: 5px;
}


// <mat-dialog>
.mat-mdc-dialog-title {
  color: white !important;
  background-color: #327ebd;
  text-align: center;
  width: 100%;
  font-size: 20px;
}

mat-dialog-content {
  padding: 20px !important;
}


/* 
menu (om vooralsnog onduidleijke reden werken sommige van deze style classes niet wanneer ze in de betreffende component CSS worden geplaatst)  
*/

$menuLight: #327ebd;
// $menuDark: #205682;
$menuDark: darken($menuLight, 10%);
// $menuActive: red;  //only for testing

mat-list-item.navmenu {
  // border: 1px dotted white;
  padding: 0px;
  height: auto !important;
  margin: 0px !important;


  .mat-expansion-indicator::after {
    color: white;
  }
  

// mat-mdc-list-item-title mdc-list-item__primary-text ng-star-inserted
  .mat-mdc-list-item-title {
    font-size: 12px !important;
    display: inline !important;
    color: white !important;
 //  color: inherit;
  }

  // display: flex;
  &:hover .mdc-list-item__primary-text {
    color: white !important;
    // background-color: $menuDark;
  }
  &:hover .mat-icon {
    color: white !important;
  }
  &.active-link {
    background-color: white !important;
    color: $menuLight !important;
    &:focus {
      background-color: white !important;
      color: $menuLight !important;
      mat-icon {
        color: $menuLight !important;
      }
    }
    mat-icon {
      color: $menuLight !important;
    }
    .mat-mdc-list-item-title {
      color: $menuLight !important;
    }
    
  }
  mat-icon {
    color: white !important;
    font-size: 18px !important;
    position: relative;
    top: 7px;
    margin: 10px !important;
    display: flex;
    align-self: flex-start !important;
  }
    
  mat-expansion-panel {
    background-color: $menuLight !important;
    mat-expansion-panel-header {
      background-color: inherit !important;
      padding-left: 2px !important;
      height: 45px !important;
      &:hover {
        background-color: darken($menuLight, 2%) !important;
      }
      mat-panel-title {
        background-color: inherit !important;
        margin-left: 0px !important;
        padding: 0px !important;
        color: white !important;
        font-size: 12px !important;
      }
      .mat-content {
        background-color: inherit !important;
      } 
    }
    .mat-expansion-panel-body {
      padding: 1px 1px !important;
      background-color: $menuLight !important;
    }
  
  }
    
}

.central {
  display: flex;
  align-items: center;
  justify-content: center;
}


mat-datepicker-toggle  svg {
  position: relative;
  bottom: 8px;  
}

mat-optgroup {
  font-weight: bolder;
}


